import { API } from "shared";

export async function makeCarpetPopular(id: number) {
  await API.post("/page-view", {
    carpet_id: id,
  }).then(() => {
    //create a session to check whether to hit api again in other page
    sessionStorage.setItem("view_session_start", id?.toString());
  });
}
