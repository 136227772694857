import { FileSearchOutlined, FilterOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/node";
import {
  Col,
  Radio,
  Result,
  Row,
  Select,
  Button as SimpleButton,
  Spin,
  Tag,
} from "antd";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { API, Button, i18n } from "shared";
import { CarpetData } from "../../../interfaces/CarpetData";
import { RangeSlider } from "../../atoms/slider";
import { CarpetCard } from "../CarpetCard";

const useStyles = createUseStyles({
  mainWrapper: {
    padding: "0px 30px",
    marginTop: 30,
    "@media(max-width: 768px)": {
      padding: "0px 5px",
      marginBottom: 24,
    },
    marginBottom: 8,
  },
  eventImage: {
    cursor: "pointer",
    filter: `brightness(110%) contrast(1) blur(0px)`,
    "@media(min-width: 870px)": {
      display: "none",
    },
  },
  rowWrapper1: {
    "@media(max-width: 768px)": {
      display: "none",
    },
  },
  rowWrapper2: {
    "@media(min-width: 769px)": {
      display: "none",
    },
  },
  buttonWrapper: {
    "@media(min-width: 769px)": {
      display: "none",
    },
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
  buttonClass1: {
    "& >span": {
      fontSize: 12,
      lineHeight: "18px",
    },
    width: 240,
  },
  optionField: {
    display: "none",

    "@media(max-width: 1060px)": {
      width: "100%",
      marginBottom: 40,

      margin: "0 auto",
      display: "block",
      "& .ant-select-selector": {
        height: "45px !important",
        padding: "8px !important",
      },
    },
  },
  sliderBar: {
    display: "grid",
    marginBottom: 70,
    padding: "0px 15px",
    gridTemplateColumns: "1fr 1fr 1fr  ",
    gridGap: 100,
    maxWidth: "100%",
    "@media(max-width: 1060px)": {
      gridTemplateColumns: "1fr ",
      maxWidth: "100%",
      gridGap: 25,
    },
  },
  sizeWrapper: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    "@media(max-width: 1060px)": {
      display: "none",
    },
  },
  singleSize: {
    minWidth: 500,
    marginBottom: 30,
    backgroundColor: "#1B2E32",
  },
  active: {
    backgroundColor: "#3A9689",
  },
  dropButton: {
    borderRadius: 4,
    height: 50,
    fontSize: 20,
    width: "50%",
  },
  filterBtn: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "start",
    marginBottom: 30,
    "@media (max-width: 660px)": {
      flexDirection: "column-reverse",
      marginTop: 10,
    },
  },
  mainTitle: {
    marginTop: 20,
    marginBottom: 40,
    fontFamily: ".Aqua Kana",
    fontSize: 18,
    lineHeight: "27px",
    "@media(max-width: 900px)": {
      marginBottom: 30,
    },
  },
  filterButton: {
    height: 40,
    marginTop: 75,
    marginBottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 1060px)": {
      marginTop: 15,
    },
  },
  resultIcon: {
    "& svg": {
      fill: "rgb(0, 150, 136)",
    },
  },
  simpleButton: {
    fontSize: 18,
    height: 40,
    backgroundColor: "#062a06",
    fontWeight: "bold",
    color: "#fff",
    borderRadius: 6,
    border: "1px solid #08062a",
    "&:hover": {
      backgroundColor: "#062a06",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: 6,
      border: "1px solid #08062a",
    },
    "@media (max-width: 550px)": {
      fontSize: 13,
      marginLeft: 5,
    },
    "@media (max-width: 660px)": {
      fontSize: 13,
      marginLeft: 5,
      marginTop: 15,
    },
  },
  optionFields: {
    "@media(max-width: 540px)": {
      fontSize: 12,
    },
    "@media(max-width: 480px)": {
      fontSize: 9.5,
    },
    "@media(max-width: 360px)": {
      fontSize: 7.5,
    },
  },
  sortBy: {
    fontSize: 15,

    color: "#ece2e2",
    fontWeight: "bold",
    maxWidth: 250,
    width: "100%",
    "@media (max-width: 550px)": {
      fontSize: 13,
      fontWeight: "500",
      maxWidth: 140,
    },
    "& .ant-select-arrow": {
      color: "#fff !important",
      fontSize: 14,
      // marginTop:0
    },
    "& .ant-select-selector": {
      backgroundColor: "#062a06 !important",
      borderRadius: "8px !important",

      "& input": {
        "&:placeholder": {
          color: "red !important",
        },
      },
      "& .ant-select-selection-placeholder": {
        color: "#fff",
      },
    },
  },
  workshop: {
    marginBottom: 15,
    margin: "0 auto",
    fontSize: 18,
    padding: 8,
    borderRadius: 8,
  },
  tagWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  radioGroup: {
    fontSize: "16px !important",
    "@media (max-width: 550px)": {
      fontSize: "12px !important",
      padding: "0px 6px",
    },
    "@media (max-width: 400px)": {
      fontSize: "11px !important",
      padding: "0px 4px",
    },
  },
  spinWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 100,
  },
  colWrapper: {
    "@media(max-width: 768px)": {
      padding: "0px !important",
      width: "100%",
    },
  },
  rowWrap: {
    "@media(max-width: 768px)": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: 20,
      justifyItems: "center",
      padding: "0px 24px",
    },
    "@media(max-width: 500px)": {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media(max-width: 420px)": {
      gridTemplateColumns: "1fr",
    },
  }
});

interface Props {
  checkMirmehdi?: boolean;
  maxImage?: number;
  showFilter?: boolean;
  intialCarpet?: any;
}
const TopPageContent: React.FC<Props> = (props) => {
  const classes = useStyles();
  const simpleButtonRef = useRef<HTMLButtonElement>(null);
  const router = useRouter();

  const { Option } = Select;
  const [filterVisible, setFilterVisible] = useState(false);
  const { showFilter } = props;
  const [carpets, setCarpets] = useState(
    props.intialCarpet || ([] as CarpetData[])
  );
  const [currentSelected, setCurrentSelected] = useState<number>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const cols1: JSX.Element[] = [];
  const cols2: JSX.Element[] = [];
  const [imageLength, setImageLength] = useState(8);
  const [filteredWidth, setFilteredWidth] = useState({
    maxWidth: 400,
    minWidth: 10,
  });
  const [filteredHeight, setFilteredHeight] = useState({
    maxHeight: 400,
    minHeight: 30,
  });
  const [sortBy, setSortBy] = useState({
    maxPrice: "",
    minPrice: "",
    recommended: "",
    newest: "",
  });

  const setFilterState = () => {
    setFilterVisible((current) => !current);
  };

  const [currentFilterOption, setCurrentFilterOption] = useState("");

  useEffect(() => {
    simpleButtonRef.current?.addEventListener("mouseenter", setFilterState);
    if (router.query.view) return;
    if (router.query.carpet) {
      fetchCarpets({ workshop: router.query.carpet });
    } else {
      fetchCarpets();
    }

    return () => {
      simpleButtonRef.current?.removeEventListener(
        "mouseenter",
        setFilterState
      );
    };
  }, [router.query.carpet, router.query.view]);

  const fetchCarpets = async (data: any = "") => {
    setLoading(true);
    try {
      const response = await API.get("/carpets", {
        params: data,
      });

      if (response && response.data) {
        setCarpets(response.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Sentry.captureException(err);
    }
  };

  const handleSeeMore = () => {
    setImageLength(imageLength + 8);
  };
  const findFilteredContent = async () => {
    await fetchCarpets({
      minHeight: filteredHeight.minHeight,
      maxHeight: filteredHeight.maxHeight,
      minWidth: filteredWidth.minWidth,
      maxWidth: filteredWidth.maxWidth,
      maxPrice: sortBy.maxPrice,
      minPrice: sortBy.minPrice,
      recommended: sortBy.recommended,
      newest: sortBy.newest,
      workshop: router.query?.carpet,
    });
  };

  carpets.map((item: CarpetData) => {
    const height = item.sizwe_height;
    const width = item.sizwe_width;
    const carpetDimension = height.toString() + " x " + width.toString();
    const binary = Number(item.status).toString(2).padStart(6, "0");
    // if (props.checkMirmehdi) {
    if (binary.charAt(1) == "1") {
      cols1.push(
        <Col key={item.id} className={classes.colWrapper}>
          <CarpetCard
            carpet_id={item.product_id}
            id={item.id}
            image={item.main_image ? item.main_image : "/assets/gray_image.png"}
            description={t(item.short_description)}
            dimension={carpetDimension}
            title={t(item.title)}
            tag={item.status!}
            fullRated={
              item?.evaluation_color == 5 &&
              item?.evaluation_density == 5 &&
              item.evaluation_feel == 5 &&
              item?.evaluation_gloss == 5 &&
              item?.evaluation_softness == 5 &&
              item?.evaluation_texture == 5
                ? true
                : false
            }
          />
        </Col>
      );
      if (cols2.length < imageLength) {
        cols2.push(
          <Col key={item.id} className={classes.colWrapper}>
            <CarpetCard
              carpet_id={item.product_id}
              id={item.id}
              image={item.main_image ?? ""}
              description={t(item.short_description)}
              dimension={carpetDimension}
              title={t(item.title)}
              tag={item.status!}
            />
          </Col>
        );
      }
    }
    // } else {
    if (!props.checkMirmehdi) {
      if (binary.charAt(1) != "1") {
        if (props.maxImage) {
          if (props.maxImage > cols1.length) {
            cols1.push(
              <Col key={item.id} className={classes.colWrapper}>
                <CarpetCard
                  carpet_id={item.product_id}
                  id={item.id}
                  image={
                    item.main_image ? item.main_image : "/assets/gray_image.png"
                  }
                  description={t(item.short_description)}
                  dimension={carpetDimension}
                  title={t(item.title)}
                  tag={item.status!}
                  fullRated={
                    item?.evaluation_color == 5 &&
                    item?.evaluation_density == 5 &&
                    item.evaluation_feel == 5 &&
                    item?.evaluation_gloss == 5 &&
                    item?.evaluation_softness == 5 &&
                    item?.evaluation_texture == 5
                      ? true
                      : false
                  }
                />
              </Col>
            );
          }
          if (props.maxImage - 1 > cols2.length) {
            cols2.push(
              <Col key={item.id} className={classes.colWrapper}>
                <CarpetCard
                  carpet_id={item.product_id}
                  id={item.id}
                  image={
                    item.main_image ? item.main_image : "/assets/gray_image.png"
                  }
                  description={t(item.short_description)}
                  dimension={carpetDimension}
                  title={t(item.title)}
                  tag={item.status!}
                  fullRated={
                    item?.evaluation_color == 5 &&
                    item?.evaluation_density == 5 &&
                    item.evaluation_feel == 5 &&
                    item?.evaluation_gloss == 5 &&
                    item?.evaluation_softness == 5 &&
                    item?.evaluation_texture == 5
                      ? true
                      : false
                  }
                />
              </Col>
            );
          }
        } else {
          cols1.push(
            <Col key={item.id} className={classes.colWrapper}>
              <CarpetCard
                carpet_id={item.product_id}
                id={item.id}
                image={
                  item.main_image ? item.main_image : "/assets/gray_image.png"
                }
                description={t(item.short_description)}
                dimension={carpetDimension}
                title={t(item.title)}
                tag={item.status!}
                fullRated={
                  item?.evaluation_color == 5 &&
                  item?.evaluation_density == 5 &&
                  item.evaluation_feel == 5 &&
                  item?.evaluation_gloss == 5 &&
                  item?.evaluation_softness == 5 &&
                  item?.evaluation_texture == 5
                    ? true
                    : false
                }
              />
            </Col>
          );
          if (cols2.length < imageLength) {
            cols2.push(
              <Col key={item.id} className={classes.colWrapper}>
                <CarpetCard
                  carpet_id={item.product_id}
                  id={item.id}
                  image={item.main_image ?? ""}
                  description={t(item.short_description)}
                  dimension={carpetDimension}
                  title={t(item.title)}
                  tag={item.status!}
                  fullRated={
                    item?.evaluation_color == 5 &&
                    item?.evaluation_density == 5 &&
                    item.evaluation_feel == 5 &&
                    item?.evaluation_gloss == 5 &&
                    item?.evaluation_softness == 5 &&
                    item?.evaluation_texture == 5
                      ? true
                      : false
                  }
                />
              </Col>
            );
          }
        }
      }
    }
  });
  const widthMark = {
    10: "10",

    150: "150",

    300: "300",

    400: "400",
  };
  const heightMark = {
    10: "10",

    150: "150",

    300: "300",

    400: "400",
  };
  // const priceMark = {
  //   1000: "1,000",

  //   5000000: "5,000,000",

  //   10000000: "10,000,000",
  // };

  const sizeOptions = [
    {
      title: "インテリア、花瓶や電話機の小物置き、座布団約30x45 cm",
      value: "30,45",
    },
    {
      title: "玄関マット、壁飾り用、小物置き約60×90 cm",
      value: "60,90",
    },
    {
      title: "幅の玄関マット（100〜125cm幅） 、壁飾り約80×120 cm",
      value: "80,120",
    },
    {
      title: "玄関マット、壁飾り部屋や 廊下のコーナー100×150 cm",
      value: "100,150",
    },

    {
      title: "二人掛けソファー、応接テーブル130×200 cm",
      value: "130,200",
    },
    {
      title: "三人掛けソファ、応接セット、リビングスペース140×240 cm",
      value: "140,240",
    },

    {
      title: "ダイニングやリビングのスペース、10〜12畳のスペース約200x300 cm～",
      value: "200,300",
    },

    {
      title: "正方形のリビングスペース100×100cm ～",
      value: "100,100",
    },
  ];

  const handleChange = async (e: any) => {
    const value = e.target.value;
    setCurrentFilterOption(value);
    window.history.pushState({}, "", "/");
    switch (value) {
      case "recommended":
        fetchCarpets({
          minHeight: filteredHeight.minHeight,
          maxHeight: filteredHeight.maxHeight,
          minWidth: filteredWidth.minWidth,
          maxWidth: filteredWidth.maxWidth,
          recommended: value,
          workshop: router.query?.carpet,
        });
        setSortBy({
          recommended: "recommended",
          minPrice: "",
          maxPrice: "",
          newest: "",
        });
        break;
      case "maxPrice":
        fetchCarpets({
          minHeight: filteredHeight.minHeight,
          maxHeight: filteredHeight.maxHeight,
          minWidth: filteredWidth.minWidth,
          maxWidth: filteredWidth.maxWidth,
          workshop: router.query?.carpet,
          maxPrice: "high",
        });
        setSortBy({
          minPrice: "",
          maxPrice: "high",
          recommended: "",
          newest: "",
        });
        break;
      case "minPrice":
        fetchCarpets({
          minHeight: filteredHeight.minHeight,
          maxHeight: filteredHeight.maxHeight,
          minWidth: filteredWidth.minWidth,
          maxWidth: filteredWidth.maxWidth,
          workshop: router.query?.carpet,
          minPrice: "low",
        });
        setSortBy({
          minPrice: "low",
          maxPrice: "",
          recommended: "",
          newest: "",
        });
        break;
      case "new":
        fetchCarpets({
          minHeight: filteredHeight.minHeight,
          maxHeight: filteredHeight.maxHeight,
          minWidth: filteredWidth.minWidth,
          maxWidth: filteredWidth.maxWidth,
          newest: "new",
          workshop: router.query?.carpet,
        });
        setSortBy({
          recommended: "",
          minPrice: "",
          maxPrice: "",
          newest: "new",
        });
        break;
      case "popular":
        fetchCarpets({
          popular: "popular",
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!router.query?.view) return;
    setCurrentFilterOption(router.query.view as string);
    if (router.query.view === "popular") {
      fetchCarpets({
        popular: "popular",
      });
    }

    if (router.query.view === "recommended") {
      fetchCarpets({
        minHeight: filteredHeight.minHeight,
        maxHeight: filteredHeight.maxHeight,
        minWidth: filteredWidth.minWidth,
        maxWidth: filteredWidth.maxWidth,
        recommended: "recommended",
        workshop: router.query?.carpet,
      });
    }
    if (simpleButtonRef?.current)
      simpleButtonRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, [router.query?.view, simpleButtonRef?.current]);

  return (
    <div className={classes.mainWrapper}>
      {router.query.carpet && router.pathname != "/mirmehdi" && (
        <div className={classes.tagWrapper}>
          {i18n.language != "ja" ? (
            <Tag
              color="#108ee9"
              closable
              onClose={() => router.push("/")}
              className={classes.workshop}
            >
              Showing carpets of: {router.query.carpet}
            </Tag>
          ) : (
            <Tag
              color="#108ee9"
              closable
              onClose={() => router.push("/")}
              className={classes.workshop}
            >
              {router.query.carpet} carpet を表示中
            </Tag>
          )}
        </div>
      )}
      {showFilter && (
        <>
          <div className={classes.filterBtn}>
            <SimpleButton
              ref={simpleButtonRef}
              className={classes.simpleButton}
              onClick={() => setFilterVisible((current) => !current)}
            >
              フィルター
            </SimpleButton>
            <Radio.Group
              id="carpet-list"
              size="large"
              onChange={handleChange}
              value={currentFilterOption}
              buttonStyle="solid"
              style={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: 6,
              }}
            >
              <Radio.Button className={classes.radioGroup} value="minPrice">
                {t("Price Low To High")}
              </Radio.Button>
              <Radio.Button className={classes.radioGroup} value="maxPrice">
                {t("Price High To Low")}
              </Radio.Button>
              <Radio.Button className={classes.radioGroup} value="recommended">
                {t("Recommended")}
              </Radio.Button>
              <Radio.Button className={classes.radioGroup} value="new">
                {t("New Arrivals")}
              </Radio.Button>
              <Radio.Button className={classes.radioGroup} value="popular">
                {t("Popular carpets")}
              </Radio.Button>
            </Radio.Group>
          </div>
          {filterVisible && (
            <div>
              <div className={classes.mainTitle}>
                絨毯のサイズを選びください
              </div>
              <div className={classes.sizeWrapper}>
                {sizeOptions.map((option, index) => (
                  <Button
                    key={index}
                    onClick={() => {
                      setCurrentSelected(index);
                      const [width, height, range] =
                        sizeOptions[index].value.split(",");
                      setFilteredHeight({
                        minHeight: Number(height) - 10,
                        maxHeight: range
                          ? Number.MAX_SAFE_INTEGER
                          : Number(height) + 15,
                      });
                      setFilteredWidth({
                        minWidth: Number(width) - 10,
                        maxWidth: range
                          ? Number.MAX_SAFE_INTEGER
                          : Number(width) + 15,
                      });
                      fetchCarpets({
                        minHeight: Number(height) - 10,
                        maxHeight: range
                          ? Number.MAX_SAFE_INTEGER
                          : Number(height) + 15,
                        minWidth: Number(width) - 10,
                        maxWidth: range
                          ? Number.MAX_SAFE_INTEGER
                          : Number(width) + 15,
                        maxPrice: sortBy.maxPrice,
                        minPrice: sortBy.minPrice,
                        recommended: sortBy.recommended,
                        newest: sortBy.newest,
                        workshop: router.query?.carpet,
                      });
                    }}
                    className={`${classes.singleSize} ${
                      currentSelected == index && classes.active
                    }`}
                  >
                    {option.title}
                  </Button>
                ))}
              </div>
              <Select
                placeholder="絨毯のサイズを選びください"
                allowClear
                onChange={(value: string) => {
                  if (value) {
                    const [width, height, range] = value.split(",");
                    setFilteredHeight({
                      minHeight: Number(height) - 20,
                      maxHeight: range
                        ? Number.MAX_SAFE_INTEGER
                        : Number(height) + 20,
                    });
                    setFilteredWidth({
                      minWidth: Number(width) - 20,
                      maxWidth: range
                        ? Number.MAX_SAFE_INTEGER
                        : Number(width) + 20,
                    });
                    fetchCarpets({
                      minHeight: Number(height) - 20,
                      maxHeight: range
                        ? Number.MAX_SAFE_INTEGER
                        : Number(height) + 20,
                      minWidth: range
                        ? Number.MAX_SAFE_INTEGER
                        : Number(width) - 20,
                      maxWidth: Number(width) + 20,
                      maxPrice: sortBy.maxPrice,
                      minPrice: sortBy.minPrice,
                      recommended: sortBy.recommended,
                      newest: sortBy.newest,
                      workshop: router.query?.carpet,
                    });
                  } else {
                    setFilteredHeight({
                      minHeight: 30,
                      maxHeight: 400,
                    });
                    setFilteredWidth({
                      minWidth: 10,
                      maxWidth: 400,
                    });
                    fetchCarpets();
                  }
                }}
                className={classes.optionField}
              >
                {sizeOptions.map((element: any) => (
                  <Option
                    className={classes.optionFields}
                    value={element.value}
                    key={element.title}
                  >
                    {element.title}
                  </Option>
                ))}
              </Select>

              <div className={classes.sliderBar}>
                <RangeSlider
                  marks={widthMark}
                  value={[filteredWidth.minWidth, filteredWidth.maxWidth]}
                  onChange={(value: any) => {
                    setFilteredWidth({
                      maxWidth: value[1],
                      minWidth: value[0],
                    });
                  }}
                  title="幅"
                  maxValue={400}
                  minValue={10}
                />
                <RangeSlider
                  marks={heightMark}
                  value={[filteredHeight.minHeight, filteredHeight.maxHeight]}
                  onChange={(value: any) => {
                    setFilteredHeight({
                      maxHeight: value[1],
                      minHeight: value[0],
                    });
                  }}
                  title="高さ"
                  maxValue={400}
                  minValue={30}
                />
                {/* <RangeSlider
                  marks={priceMark}
                  value={[priceRange.minPrice, priceRange.maxPrice]}
                  onChange={(value: any) => {
                    setPriceRange({ maxPrice: value[1], minPrice: value[0] });
                  }}
                  title="価格"
                  maxValue={10000000}
                  minValue={1000}
                /> */}
                <Button
                  size="middle"
                  loading={loading}
                  className={classes.filterButton}
                  onClick={findFilteredContent}
                >
                  <FilterOutlined />
                  {"検索"}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {loading ? (
        <div className={classes.spinWrapper}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {carpets.length === 0 ? (
            <Result
              icon={<FileSearchOutlined className={classes.resultIcon} />}
              title="No Carpet found"
            />
          ) : (
            <div>
              <div className={classes.rowWrapper1}>
                <Row gutter={[48, 48]}>{cols1}</Row>
              </div>
              <div className={classes.rowWrapper2}>
                <Row gutter={[50, 22]} justify="center" className={classes.rowWrap}>
                  {cols2}
                </Row>
              </div>
              {imageLength < cols1.length && (
                <div className={classes.buttonWrapper}>
                  <Button
                    size="large"
                    className={classes.buttonClass1}
                    onClick={handleSeeMore}
                  >
                    {t("See more")}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { TopPageContent };
